type MouseEventName =
	| 'click'
	| 'dblclick'
	| 'mousedown'
	| 'mouseup'
	| 'mouseover'
	| 'mouseout'
	| 'mousemove'
	| 'mouseenter'
	| 'mouseleave';
type FocusEventName = 'focus' | 'blur' | 'focusin' | 'focusout';
type MouseScrollEventName = 'wheel' | 'scroll';
type WheelEventName = 'wheel';
type KeyboardEventName = 'keydown' | 'keypress' | 'keyup';
type InputEventName =
	| 'input'
	| 'change'
	| 'focus'
	| 'blur'
	| 'select'
	| 'submit'
	| 'reset';
type GenericEventName =
	| 'load'
	| 'unload'
	| 'beforeunload'
	| 'resize'
	| 'scroll'
	| 'hashchange'
	| 'popstate'
	| 'invalid';

export type EventType<T extends string> = T extends MouseEventName
	? MouseEvent
	: T extends KeyboardEventName
	? KeyboardEvent
	: T extends InputEventName
	? InputEvent
	: T extends FocusEventName
	? FocusEvent
	: T extends MouseScrollEventName
	? WheelEvent
	: T extends WheelEventName
	? WheelEvent
	: T extends GenericEventName
	? Event
	: CustomEvent;

export function onDelegatedEvent<T extends string, E extends HTMLElement>(
	eventName: T | T[],
	selector: string,
	callback: (event: EventType<T>, el: E) => void
): void {
	const eventNames = Array.isArray(eventName) ? eventName : [eventName];
	eventNames.forEach((eventName) => {
		document.addEventListener(
			eventName,
			(event: Event) => {
				const target = event.target;
				if (!(target instanceof HTMLElement)) return;
				const element: E | null = target.closest(selector);
				if (element == null) return;
				callback(event as EventType<T>, element);
			},
			true
		);
	});
}
